
import { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import './find-meal.styles.scss';
import OptionsCard from '../../components/options-card.component/options-card.component';
import MyMealsCard from '../../components/mymeals-card.component/mymeals-card.component';
import GroceryCategory from '../../components/grocery-category.component/grocery-category.component';
import Print from '../../components/react-to-print.component/react-to-print';
import ParentPrint from '../../components/react-to-print.component/parent-print.component';
import Loader from '../../components/loader.component/loader.component';
import { Link } from 'react-router-dom';
import recipes from '../../data/recipe-db/recipe.db.json';


const FindMeals = () => {

    const printRef = useRef();
    const [season, setSeason] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [recipeList, setRecipeList] = useState([]);
    const [myMeals, setMyMeals] = useState([]);
    const [ingredientsList, setIngredientsList] = useState([]);
    const [groceryCategories, setGroceryCategories] = useState([]);
    const [showPrint, setShowPrint] = useState(false);
    const [loading, setLoading] = useState(false);



    // useEffect(() => {
    //     // const getRecipes = async () => {

    //     //     const response = await fetch('https://wtfdatabase.onrender.com/recipes');
    //     //     const data = await response.json();
    //     //     console.log(data);
    //     //     setRecipeList(data);
    //     //     setLoading(false);     
    //     // }

    //     const getRecipes = async () => {
    //         const response = await fetch('./././recipe-db/recipe.db.json');
    //         const data = await response.json();
    //         console.log(data);
    //         setRecipeList(data);
    //         setLoading(false);
    //     }
    //     getRecipes();

    // }, []);

    useEffect(() => {


        const getRecipes = () => {
            const response = recipes.recipes;
            console.log(response, 'response');
            const data = response;
            console.log(data, 'data');
            setRecipeList(data);
            setLoading(false);
        }
        getRecipes();


    }, []);




    const handleFindRecipes = () => {
        setShowOptions(true);

        if (recipeList.length === 0) {
            setLoading(true)
        }

    };

    const myOptions = season === "All"
        ? recipeList : (season === '')
            ? []
            : recipeList.filter(item => item.season === season.toLowerCase() || item.season === "both");


    const addToMeals = (recipe) => {
        setMyMeals([...myMeals, recipe]);
    };

    const deleteMeal = (meal) => {
        let deleteId = meal.id;
        setMyMeals([...myMeals.filter(item => item.id !== meal.id)])
    };

    const doubleHalfRecipe = (meal, num) => {
        const myNewMapArray = myMeals.map(item => {
            if (item.id === meal.id) {
                item.serves *= num
                item.ingredientsList.forEach(ing => {
                    ing.largeIncrement = ing.largeIncrement * num;
                    ing.smallIncrement = ing.smallIncrement * num;
                })
            }
            return item;
        })


        setMyMeals(myNewMapArray);
    };

    const getGroceryList = () => {

        const ingredients = {
            'fake': {
                amount: 0,
                unit: '',
                category: ''
            }
        };
        myMeals.forEach(meal => meal.ingredientsList.forEach(ing => {
            if (ingredients[ing.name] && ingredients[ing.name].unit === ing.measureMethod) {
                ingredients[ing.name].amount += Number(ing.largeIncrement) + Number(ing.smallIncrement);


            } else {
                ingredients[ing.name] = { amount: Number(ing.largeIncrement) + Number(ing.smallIncrement), unit: ing.measureMethod, category: ing.category }
            }

        }));
        delete ingredients.fake;
        //changes ingredients back into an array
        let makeObjectsIntoArray = Object.entries(ingredients);
        let ingOnList = {
            name: '',
            amount: 0,
            unit: '',
            category: ''
        }
        let newList = [];
        for (let i = 0; i < makeObjectsIntoArray.length; i++) {
            ingOnList.name = makeObjectsIntoArray[i][0];
            ingOnList.amount = makeObjectsIntoArray[i][1].amount;
            ingOnList.unit = makeObjectsIntoArray[i][1].unit;
            ingOnList.category = makeObjectsIntoArray[i][1].category;
            let clone = { ...ingOnList };
            newList.push(clone)
        }


        return newList;
    };

    const deleteIngredient = (key, item) => {
        let tempList = [...groceryCategories];
        let listIndex = tempList.indexOf(item);
        let deleteIndex = tempList[listIndex].indexOf(key);
        tempList[listIndex].splice(deleteIndex, 1);
        setGroceryCategories(tempList);
    };

    const aisleCategories = () => {
        const newList = getGroceryList();
        setIngredientsList(newList);
        let produce = newList.filter(item => item.category === 'Produce');
        let spiceOils = newList.filter(item => item.category === "Spice/Oils");
        let dairy = newList.filter(item => item.category === 'Dairy');
        let meatSeafood = newList.filter(item => item.category === "Meat/Seafood");
        let deli = newList.filter(item => item.category === "Deli");
        let dryGoods = newList.filter(item => item.category === "Dry Goods");
        let cannedJarredGoods = newList.filter(item => item.category === "Canned/Jarred Goods");
        let frozenFoods = newList.filter(item => item.category === "Frozen Foods");
        let bakery = newList.filter(item => item.category === "Bakery");
        let condiments = newList.filter(item => item.category === "Condiment/s");
        let refrigerated = newList.filter(item => item.category === "Refrigerated");

        let categories = [];
        categories.push(produce, spiceOils, dairy, meatSeafood, deli, dryGoods, cannedJarredGoods, frozenFoods, bakery, condiments, refrigerated);
        setGroceryCategories(categories);
        setShowPrint(true);
    };


    return (
        <div className='find-meals-container'>

            <div className="filter-meals">
                <h1>Let's figure out what's for dinner!</h1>
                {/* <h2>1. Select Meal Season</h2> */}
                <p className='findmealstext'>Is the weather sizzling or chilly at the moment?</p>
                <div className="season-input">
                    <select name="season" id="season" onChange={(e) => setSeason(e.target.value)}>
                        <option value="">Choose Season</option>
                        <option value="Warm">warm</option>
                        <option value="Cool">cool</option>
                        <option value="All">all</option>
                    </select>
                    <button id="findmeals" type="button" className="large-button" onClick={handleFindRecipes}>Find Recipes</button>
                    <div className='loading'>
                        {loading && <Loader />}
                    </div>
                </div>





                <div className='my-options' id='my-options'>
                    {/* <h2>2. My Options</h2> */}
                    {showOptions && <p className="findmealstext">Here are recipe options for you. Click "add" to include them in your meal list. You can adjust quantities and remove later if needed. Found something appealing? Go ahead and add it!</p>}
                    <div className='options-container' id='options-container'>
                        {showOptions && myOptions.map((option) => {
                            return <OptionsCard recipe={option} addToMeals={addToMeals} myMeals={myMeals} />

                        })}

                    </div>

                </div>


                <div className='meals-section' >
                    {/* <h2>3. My Meals</h2> */}
                    
                    {myMeals.length>= 1 && <p className="findmealstext">Ta-da! Behold the recipes you've handpicked. If any don't tickle your taste buds, give 'em the boot. Keep an eye on the serving sizes – feel free to double up for a feast or halve for a mini party. It's your culinary adventure! </p>}
                    <div id='my-meals'>
                    
                        {myMeals.map((meal) => {
                            return <MyMealsCard recipe={meal} doubleHalfRecipe={doubleHalfRecipe} deleteMeal={deleteMeal} />

                        })}
                    </div>
                    {myMeals.length>= 1 && <p className="findmealstext">Bravo! Once you've worked your magic, your ultimate dinner lineup will be set. Ready to roll? Hit that button down below to snag your sensational grocery list!</p>}
                </div>
                <div className='grocery-list'>
                    <button type='button' className="large-button" onClick={() => { aisleCategories() }}>View Grocery List</button>
                    {groceryCategories.length >= 1 && <p className="findmealstext">Welcome to your personalized grocery treasure map, sorted by aisles of wonder! Give it a whirl and cross off items you've already got. Ready to complete your culinary conquest? Add in anything else you will need on your voyage through the grocery store!</p>}
                    <div className='plan-grocery-container'>

                        {/* {ingredientsList.map(item => <div>{item.name} {item.amount} {item.unit} <button type="button" onClick={()=>{deleteIngredient(item)}}>x</button></div>)} */}
                       
                        {groceryCategories.map((item) => {
                            if (item.length > 0) {
                                return <GroceryCategory category={item[0].category} item={item} deleteIngredient={deleteIngredient}
                                    groceryCategories={groceryCategories} setGroceryCategories={setGroceryCategories} />
                            }
                        })}



                    </div>
                    <div>
                        {showPrint && <Link to="/print" state={{ myMeals, groceryCategories }} ><button className="large-button" type="button">View Meal Plan & Grocery List</button></Link>}

                    </div>
                </div>


            </div>

        </div>
    )
};

export default FindMeals