import './cookbook-shop.styles.scss';
import ShopItem from '../../components/shop-item.component/shop.item.component';

const CookbookShop =()=> {

    return (
       
        <div className="the-shop">
           

            <ShopItem/>
            
        </div>
    )
};

export default CookbookShop