import './home.component.scss'
import homeSplash from '../../assets/stefan-vladimirov-Q_Moi2xjieU-unsplash.jpg';
import altSplash from '../../assets/rotatesplash.jpg';



const Home =()=> {

    return (
        <div className='home-container' id='home-container'>

            <h1 className='splash-text' id="splash-text-one">The mere asking of this question can cause chaos, mental-breakdown, murderous thoughts, depression and divorce . . .</h1>


<h1 className='splash-text' id='splash-text-two'>Let's simplify dinner !</h1>

            <div className='splash-image'>
           <img src={homeSplash}  className='splash'/>
           </div>
        </div>
    )
};

export default Home