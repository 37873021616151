
import './loader.styles.scss';
const Loader = ()=> {

    return (

        <h1 className=" blink"> LOADING ... </h1>
    )
};

export default Loader;